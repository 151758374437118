// Core
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { TranslocoModule } from '@ngneat/transloco'

import { ButtonComponent } from '../../ui/button/button.component'
import { DialogComponent } from '../../ui/dialog/dialog.component'

@Component({
  selector: 'sb-facebook-login-dialog',
  template: `
    <ng-container *transloco="let t">
      <sb-dialog [title]="t('facebook.ui.HowToGrantPermissions')">
        <ng-template #content>
          <video
            autoplay
            class="h-full rounded-xl lg:hidden"
            loop
            muted
            src="/assets/videos/facebook-connection-tutorial.mp4"
          ></video>
          <video
            autoplay
            class="hidden h-full rounded-xl lg:block"
            loop
            muted
            src="/assets/videos/facebook-connection-tutorial-lg.mp4"
          ></video>
        </ng-template>
        <ng-template #actions>
          <sb-button variant="text" (sbClick)="onCancel()">
            {{ t('actions.Cancel') }}
          </sb-button>
          <sb-button (sbClick)="onConfirm()" [disabled]="isDisabled">
            {{ t('facebook.actions.Confirm') }}
            @if (secondsLeft > 0) {
              <span>({{ secondsLeft }})</span>
            }
          </sb-button>
        </ng-template>
      </sb-dialog>
    </ng-container>
  `,
  standalone: true,
  imports: [TranslocoModule, DialogComponent, ButtonComponent],
})
export class FacebookLoginDialogComponent implements OnInit, OnDestroy {
  @Output() confirm: EventEmitter<boolean> = new EventEmitter()
  isDisabled = true
  secondsLeft = 5

  ngOnDestroy(): void {
    this.confirm.emit(false)
  }

  ngOnInit(): void {
    const interval = setInterval(() => {
      this.secondsLeft--
      if (this.secondsLeft <= 0) {
        clearInterval(interval)
        this.isDisabled = false
        return
      }
    }, 1000)
  }

  onCancel() {
    this.confirm.emit(false)
  }

  onConfirm() {
    this.confirm.emit(true)
  }
}
