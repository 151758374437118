import { NgClass, NgTemplateOutlet } from '@angular/common'
import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core'

import { LinkedinAccount } from '~features/integrations/models/linkedin-account.model'

import { AvatarComponent } from '../../ui/avatar/avatar.component'
import { TextComponent } from '../../ui/text/text.component'

@Component({
  selector: 'sb-linkedin-account',
  templateUrl: './linkedin-account.component.html',
  styleUrls: ['./linkedin-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, AvatarComponent, TextComponent, NgTemplateOutlet],
})
export class LinkedinAccountComponent {
  @Input() isActive = false
  @Input() isMenuItem = false
  @Input() linkedinAccount: LinkedinAccount
  @ContentChild('rightSideTemplate') rightSideTemplateRef: TemplateRef<unknown>
}
