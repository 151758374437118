import { NgStyle } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { TranslocoModule } from '@ngneat/transloco'

import { Icon } from '~common/icons'
import { LinkedinAccount } from '~features/integrations/models/linkedin-account.model'

import { ButtonComponent } from '../../ui/button/button.component'
import { TextComponent } from '../../ui/text/text.component'
import { LinkedinAccountComponent } from '../linkedin-account/linkedin-account.component'

@Component({
  selector: 'app-linkedin-account-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoModule,
    MatListModule,
    LinkedinAccountComponent,
    ButtonComponent,
    TextComponent,
    MatIconModule,
    NgStyle,
  ],
  template: `
    <ng-container *transloco="let t">
      @for (linkedinAccount of linkedinAccounts; track linkedinAccount; let last = $last) {
        <mat-list>
          <mat-list-item>
            <sb-linkedin-account [linkedinAccount]="linkedinAccount">
              <ng-template #rightSideTemplate>
                <div class="mt-5 flex flex-wrap items-center justify-center md:mt-0">
                  <!-- Make primary -->
                  @if (enableMakePrimary && !linkedinAccount.is_primary) {
                    <sb-button
                      variant="text"
                      (sbClick)="onMakePrimaryLinkedinAccount(linkedinAccount)"
                      [icon]="Icons.Verified"
                    >
                      {{ t('actions.MakePrimaryAccount') }}
                    </sb-button>
                  }
                  @if (enableMakePrimary && linkedinAccount.is_primary) {
                    <sb-text class="px-6" variant="labelLarge" muted truncate noMargin>
                      <div class="flex items-center">
                        <mat-icon
                          [svgIcon]="Icons.Verified"
                          [ngStyle]="{ width: '20px', heigth: '20px' }"
                          class="mr-2"
                        />
                        {{ t('info.PrimaryAccount') }}
                      </div>
                    </sb-text>
                  }
                  <!-- Refresh token -->
                  <sb-button (sbClick)="onRefreshLinkedinAccount(linkedinAccount)" variant="text" [icon]="Icons.Cached">
                    {{ t('actions.RefreshAuth') }}
                  </sb-button>
                  <!-- Delete -->
                  @if (enableDelete) {
                    <sb-button
                      (sbClick)="onDeleteLinkedinAccount(linkedinAccount)"
                      variant="text"
                      themeColor="warn"
                      [icon]="Icons.Delete"
                    >
                      {{ t('actions.Delete') }}
                    </sb-button>
                  }
                </div>
              </ng-template>
            </sb-linkedin-account>
          </mat-list-item>
        </mat-list>
      }
    </ng-container>
  `,
})
export class LinkedinAccountListComponent {
  @Output() deleteLinkedinAccount: EventEmitter<LinkedinAccount> = new EventEmitter<LinkedinAccount>()
  @Input() enableDelete = false
  @Input() enableMakePrimary = false
  @Input() linkedinAccounts: LinkedinAccount[]
  @Output() makePrimaryLinkedinAccount: EventEmitter<LinkedinAccount> = new EventEmitter<LinkedinAccount>()
  @Output() refreshLinkedinAccount: EventEmitter<LinkedinAccount> = new EventEmitter<LinkedinAccount>()
  protected readonly Icons = Icon

  onDeleteLinkedinAccount(linkedinAccount: LinkedinAccount): void {
    this.deleteLinkedinAccount.emit(linkedinAccount)
  }

  onMakePrimaryLinkedinAccount(linkedinAccount: LinkedinAccount): void {
    this.makePrimaryLinkedinAccount.emit(linkedinAccount)
  }

  onRefreshLinkedinAccount(linkedinAccount: LinkedinAccount): void {
    this.refreshLinkedinAccount.emit(linkedinAccount)
  }
}
