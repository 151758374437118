<div
  [ngClass]="{ 'menu-item': isMenuItem }"
  class="flex w-full flex-col items-center justify-start md:flex-row md:justify-between"
>
  <div class="flex w-full items-center md:w-auto">
    <sb-avatar
      size="sm"
      [src]="linkedinAccount?.picture_url"
      [title]="linkedinAccount?.first_name + ' ' + linkedinAccount?.last_name"
    />
    <div class="px-1.5">
      <sb-text variant="bodyMedium" noMargin
        >{{ linkedinAccount?.first_name }} {{ linkedinAccount?.last_name }}</sb-text
      >
      <!-- Category -->
      <sb-text variant="label" noMargin>{{ linkedinAccount?.email }} </sb-text>
    </div>
  </div>
  @if (rightSideTemplateRef) {
    <ng-container [ngTemplateOutlet]="rightSideTemplateRef"></ng-container>
  }
</div>
